import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, useSearchParams } from "react-router-dom";

export default function KontaktFormular() {
    const [searchParams] = useSearchParams();
    const [betreff, setBetreff] = useState<string>("")

    useEffect(() => {
        setBetreff(searchParams.get("betreff") || "")
    }, [searchParams])
    return <Form style={{marginTop: "48px"}}>
        <TextField className="kontakt-formular-margin-top kontakt-formular-bg-light-green" variant="filled" label="Name" fullWidth />
        <TextField className="kontakt-formular-margin-top kontakt-formular-bg-light-green" variant="filled" label="E-Mail" fullWidth required />
        <TextField className="kontakt-formular-margin-top kontakt-formular-bg-light-green" variant="filled" label="Betreff" fullWidth required value={betreff} onChange={(e) => setBetreff(e.target.value)} />
        <TextField className="kontakt-formular-margin-top kontakt-formular-bg-light-green" variant="filled" label="Nachricht" fullWidth required multiline maxRows={5} minRows={5} />
        <Button className="kontakt-formular-margin-top" style={{textTransform: "none"}} variant="contained" fullWidth >Senden</Button>
    </Form>
}