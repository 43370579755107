import { Typography, useTheme } from "@mui/material";
import Leistung, { LeistungType } from "./Leistung";
import Akupunkturnadeln from "./icons/Akupunturnadeln";
import Anamnesebogen from "./icons/Anamnsesebogen";
import Blut from "./icons/Blut";
import Heu from "./icons/Heu";
import Pferd from "./icons/Pferd";
import "./leistungen.css";

export default function Leistungen() {
  const theme = useTheme();
  const leistungen: LeistungType[] = [
    {
      title: "Ostheopathie",
      beschreibung: "Ostheopathische Behandlungen von Pferden.",
      icon: <Anamnesebogen />,
    },
    {
      title: "Akupunktur",
      beschreibung: "Irgendwas akupunkturmäßiges.",
      icon: <Akupunkturnadeln />,
    },
    {
      title: "Tensegrales Training",
      beschreibung:
        "Tensegrales Trianing von Pferden durch Bodenarbeit und Longieren.",
      icon: <Pferd />,
    },
    {
      title: "Futterberatung",
      beschreibung: "Ostheopathische Behandlungen von Pferden.",
      icon: <Heu />,
    },
    {
      title: "Blutabhnahme",
      beschreibung: "Blutabnahme und Analyse der Blutwerte.",
      icon: <Blut />,
    },
  ];
  return (
    <div id="leistungen-background">
      <div
        id="leistungen-shadow"
        style={{ color: theme.palette.primary.contrastText }}
      >
        <div id="leistungen-content">
          <Typography align="center" variant="h1">
            Leistungen
          </Typography>
          <hr style={{ margin: "2% 5%" }} />
          <div className="leistungen-liste">
            {leistungen.map((leistung, i) => (
              <Leistung leistung={leistung} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
