import { Typography, useTheme } from "@mui/material";
import "./Preise.css";
import PreisBox, { PreisType } from "./PreisBox";

export default function Preise() {
  const theme = useTheme();
  const preise: PreisType[] = [
    {
      leistung: "Osteopathische Erstbehandlung",
      beschreibung: "Eine Erstbehandlung ist die erste Behandlung.",
      preis: "140 € zzgl. Anfahrt",
    },
    {
      leistung: "Osteopathische Folgehandlung",
      beschreibung:
        "Eine Folgehandlung ist eine Behandlung nach vorheriger Erstbehandlung.",
      preis: "120 € zzgl. Anfahrt",
    },
    {
      leistung: "Blutabhname",
      beschreibung: "Blutabnahme und Analyse der Blutwerte.",
      preis: "50 € zzgl. Laborpreise und Anfahrt",
    },
    {
      leistung: "Akupunktur",
      beschreibung: "Pieksen mit Nadeln.",
      preis: "40 € zzgl. Anfahrt",
    },
    {
      leistung: "Tensegrales Training",
      beschreibung: "Eine einstündige Einheit Tensegrales Training.",
      preis: "100 € zzgl. Anfahrt",
    },
    {
      leistung: "Futterberatung",
      beschreibung: "Beurteilung des Ernährungszustandes und Futterberatung.",
      preis: "80 € zzgl. Anfahrt",
    },
  ];
  return (
    <div id="preise-background">
      <div
        id="preise-shadow"
        style={{ color: theme.palette.primary.contrastText }}
      >
        <div id="preise-content">
          <Typography align="center" variant="h1">
            Preise
          </Typography>
          <hr style={{ margin: "2% 5%" }} />
          <div className="preise-liste">
            {preise.map((preis, i) => (
              <PreisBox preis={preis} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
