import { Typography } from "@mui/material";
import { ReactNode } from "react";

export const iconSx = { fontSize: 100 };

export type LeistungType = {
  title: string;
  beschreibung: string;
  icon: ReactNode;
};

export default function Leistung(props: { leistung: LeistungType }) {
  return (
    <div className="leistung-wrap">
      <div className="leistung">
        {props.leistung.icon}
        <br />
        <Typography variant="h2" textAlign="center" fontSize={36}>
          {props.leistung.title}
        </Typography>
        <br />
        <Typography className="details" variant="body1" textAlign="center">
          {props.leistung.beschreibung}
        </Typography>
      </div>
    </div>
  );
}
