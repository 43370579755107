import { Typography, useTheme } from "@mui/material";
import "./UeberMich.css";
export default function UeberMich() {
  const theme = useTheme();
  return (
    <div
      id="ueber-mich-background"
      style={{ backgroundColor: theme.palette.secondary.main }}
    >
      <div id="ueber-mich-container">
        <Typography variant="h1">Über mich</Typography>
        <div id="ueber-mich-content-wrapper">
          <div id="ueber-mich-content">
            <div id="ueber-mich-bilder-wrapper">
              <div id="ueber-mich-bilder" className="ueber-mich-bubble-liste">
                <div id="ueber-mich-portrait" className="ueber-mich-bubble" />
                <div
                  id="ueber-mich-logo"
                  className="ueber-mich-bubble"
                  style={{ borderColor: theme.palette.primary.main }}
                />
              </div>
            </div>
            <div id="ueber-mich-text-wrapper">
              <div id="ueber-mich-text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Maecenas posuere purus a mattis condimentum. In a sapien
                  venenatis, fermentum tellus varius, vulputate libero. Etiam
                  ullamcorper enim in luctus semper. Integer tellus libero,
                  aliquet sed iaculis ac, semper in metus. Maecenas vel est
                  dictum ante lobortis lacinia sed ut ante. Proin ullamcorper
                  ante sit amet dignissim lobortis. Mauris consectetur posuere
                  consectetur. Etiam quis ex ac ligula egestas varius. Nullam
                  ultricies velit vel commodo scelerisque. Cras sagittis odio
                  augue, et convallis neque finibus tempus. Nullam malesuada et
                  odio eget efficitur. Praesent non neque et ex lobortis
                  ultrices.
                </p>
                <p>
                  Vivamus eu tempus odio. Mauris blandit purus non dui semper
                  porttitor. Ut sed dolor lacinia, vulputate tortor vitae,
                  eleifend nisl. In nec suscipit nisi. Nulla nec lacus nunc.
                  Maecenas consequat ligula ac fermentum pretium. Sed ultrices
                  auctor dolor, id iaculis risus. Integer eget arcu libero. Nunc
                  vitae ipsum eu eros facilisis ultrices vitae et lectus.
                  Vestibulum vehicula elementum sapien. Nam sed tristique leo,
                  ac egestas libero. Proin non eros at est rutrum accumsan.
                  Fusce non placerat neque, vel elementum nisi.
                </p>
                <p>
                  Nulla nec iaculis felis, ac commodo urna. Pellentesque finibus
                  odio vitae nulla sollicitudin, sit amet dapibus augue
                  sagittis. Donec gravida tincidunt ante vel condimentum. Nunc
                  nibh urna, facilisis eu diam vel, fermentum aliquet tellus.
                  Vivamus accumsan velit sed neque rhoncus dictum. Vestibulum eu
                  mattis metus. Ut vitae dui in nibh finibus auctor. Vestibulum
                  blandit eu purus in egestas. Nulla sed ligula at enim porta
                  luctus. Proin malesuada erat eu metus pretium convallis. Sed
                  convallis purus placerat, vestibulum lacus et, ultrices
                  ligula.
                </p>
                <p>
                  Sed in libero eu augue commodo ullamcorper. Pellentesque ut
                  ultrices magna. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Ut semper ex nec
                  tellus ultricies scelerisque. Etiam scelerisque, lacus sit
                  amet vehicula fringilla, dolor ligula vulputate risus, ut
                  vulputate enim justo eu tellus. Nunc sodales eu orci sed
                  fermentum. Nunc ac mauris rutrum ipsum lobortis porttitor.
                  Fusce mattis justo ex, vel consectetur nulla placerat vel.
                  Proin suscipit nisl lorem. Vivamus malesuada faucibus
                  pharetra. Vivamus consequat felis hendrerit ante tincidunt,
                  sit amet tempor felis maximus. Nunc eget sagittis justo.
                </p>
                <p>
                  Aliquam erat volutpat. Curabitur imperdiet sem sed dapibus
                  porta. In at iaculis nunc, quis viverra nibh. Duis maximus
                  viverra lectus, eu dictum lectus posuere sed. Fusce metus
                  tellus, aliquam eget tellus non, placerat efficitur quam.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Interdum et malesuada fames ac
                  ante ipsum primis in faucibus. Integer fermentum, lorem et
                  finibus mattis, lectus nibh posuere erat, vitae posuere mi
                  nisl vitae urna. Ut fermentum lacus augue, a condimentum lacus
                  pellentesque facilisis. Aenean a dui finibus, euismod tellus
                  eget, mollis augue. Cras eget iaculis dui.
                </p>
              </div>
            </div>
            <div id="ueber-mich-lizenzen-wrapper">
              <div id="ueber-mich-lizenzen" className="ueber-mich-bubble-liste">
                <div id="ueber-mich-zepo" className="ueber-mich-bubble" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
