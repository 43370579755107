import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from "@mui/icons-material/Phone";
import { Typography, useTheme } from "@mui/material";
import "./Kontakt.css";
import KontaktFormular from './KontaktFormular';

export default function Kontakt() {
  const theme = useTheme();
  return (
    <div id="kontakt-background">
      <div
        id="kontakt-shadow"
      >
        <div className="kontakt-box" style={{
          color: theme.palette.primary.main
        }}>
          <Typography variant="h2" textAlign="center" fontSize={48}>
            So erreichst du mich
          </Typography>
          <hr className='kontakt-hr' style={{backgroundColor: theme.palette.primary.main}} />
          <div className="kontakt-zeile">
            <PhoneIcon />
            <Typography variant="body1" marginLeft={1} fontSize={24} textAlign="center">
              <a href="tel:+4915168439502" className='kontakt-link' >
                +49 151 68439502
              </a>
            </Typography>
          </div>
          <div className="kontakt-zeile">
            <EmailIcon />
            <Typography variant="body1" marginLeft={1} fontSize={24} textAlign="center">
              <a href="mailto:info@holistic-equestrian.de" className='kontakt-link' >
                info@holistic-equestrian.de
              </a>
            </Typography>
          </div>
          <KontaktFormular />
        </div>
      </div>
    </div>
  );
}
