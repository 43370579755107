import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./Content.css";
import Home from "./components/home/Home";
import Kontakt from "./components/kontakt/Kontakt";
import Leistungen from "./components/leistungen/Leistungen";
import Preise from "./components/preise/Preise";
import UeberMich from "./components/uebermich/UeberMich";
import Datenschutzerklaerung from "./components/rechtliches/Datenschutzerklaerung";
import Impressum from "./components/rechtliches/Impressum";

export default function Content() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <UeberMich />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/kontakt",
      element: <Kontakt />,
    },
    {
      path: "/ueber-mich",
      element: <UeberMich />,
    },
    {
      path: "/leistungen",
      element: <Leistungen />,
    },
    {
      path: "/preise",
      element: <Preise />,
    },
    {
      path: "/datenschutzerklaerung",
      element: <Datenschutzerklaerung />,
    },
    {
      path: "/impressum",
      element: <Impressum />,
    },
  ]);

  return (
    <div id="content">
        <RouterProvider router={router} />
      </div>
  );
}
