import { SvgIcon } from "@mui/material";
import { iconSx } from "../Leistung";

export default function Pferd() {
    return (
      <SvgIcon sx={iconSx}>
        <svg
          fill="currentColor"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 131.483 131.483"
        >
          <g>
	<path d="M126.116,70.009c1.274-0.894,2.295-1.609,3.417-2.396c0.112,0.573,0.178,1,0.274,1.519c0.487-0.193,0.935-0.366,1.676-0.66
		c-0.96,1.838-1.792,3.422-2.712,5.189c1.361,2.509,2.519,5.251,2.691,8.693c-0.528-0.716-0.838-1.138-1.289-1.757
		c-0.133,2.396,0.761,4.58-0.315,6.937c-0.294-1.971-0.543-3.737-0.853-5.911c-1.122,1.869-1.579,3.555-1.625,5.327
		c-0.081,2.712-0.005,5.429-0.081,8.145c-0.092,3.058-0.762,5.932-2.661,8.461c-1.016,1.34-2.133,2.056-3.839,1.63
		c-0.311-0.071-0.65,0.01-0.976,0.01c-0.427-0.01-0.894,0.066-1.265-0.092c-0.817-0.324-1.579-0.802-2.376-1.178
		c-1.346-0.609-1.671-1.066-1.143-2.417c0.574-1.452,0.314-2.722-0.289-4.083c-0.854-1.909-1.534-3.91-2.407-5.819
		c-0.467-1.025-1.117-1.97-1.777-2.884c-2.422-3.362-2.549-6.652,0.162-9.902c0.635-0.771,0.818-1.574,0.498-2.519
		c-0.27-0.782-0.365-1.625-0.67-2.387c-1.991-4.865-4.754-9.229-8.542-12.9c-1.396-1.358-2.849-2.704-5.098-2.854
		c-1.407,2.412-2.031,5.464-4.946,6.353c-0.777,3.633-1.655,6.98-2.173,10.387c-0.468,3.036-0.544,6.139-0.752,9.206
		c-0.111,1.727-0.035,3.474-0.27,5.189c-0.432,3.103-0.137,6.149,0.279,9.217c0.315,2.28,1.65,4.062,2.996,5.683
		c1.438,1.727,2.214,3.696,3.377,5.85c-2.366,0.894-4.61,1.066-6.87,0.437c-0.909-0.249-1.59-0.863-1.396-2.016
		c0.071-0.438-0.183-1.001,0.01-1.341c1.046-1.828-0.248-2.783-1.355-3.768c-1.011-0.914-1.468-1.95-1.544-3.352
		c-0.162-3.453-0.478-6.906-0.767-10.349c-0.071-0.823-0.279-1.636-0.437-2.438c-0.925-4.637-1.844-9.263-2.783-13.904
		c-0.021-0.121-0.122-0.248-0.243-0.472c-0.214,0.243-0.468,0.427-0.579,0.675c-1.392,3.154-3.077,6.2-3.788,9.639
		c-0.214,1.005-0.6,2.031-1.087,2.945c-2.057,3.844-2.976,7.957-3.463,12.263c-0.31,2.728-0.056,5.21,1.127,7.663
		c0.508,1.051,0.767,2.224,1.219,3.574c-2.56,0.32-4.829,0.488-7.018-0.467c-0.137-1.463,0.229-3.707,0.64-4.936
		c0.487-1.442,0.167-2.925-0.548-4.134c-0.828-1.411-0.782-2.65-0.152-4.052c2.067-4.601,2.6-9.547,3.062-14.493
		c0.32-3.523,0.579-7.048,0.935-10.562c0.097-0.884,0.446-1.737,0.665-2.61c0.295-1.147,0.543-2.29,0.879-3.669
		c-3.605-0.505-6.927-1.236-10.273-1.394c-9.887-0.452-19.052-3.225-27.609-8.153c-1.229-0.708-2.508-1.333-3.691-1.95
		c-1.529,0.68-2.402,1.727-3.123,3.021c-1.752,3.151-2.742,6.495-2.844,10.087c-0.091,3.057-0.965,5.937-2.062,8.724
		c-1.168,2.986-1.432,6.14-1.98,9.232c-0.132,0.776,0.061,1.65,0.254,2.447c0.909,3.717,1.828,7.429,2.808,11.126
		c0.493,1.843,1.508,3.433,2.864,4.783c2.067,2.066,3.174,4.707,4.403,7.308c-0.853,1.229-2.087,0.746-3.138,0.832
		c-1.107,0.087-2.229,0.021-3.509,0.021c0.015-2.138-1.244-3.661-1.265-5.591c-0.015-1.219-0.812-2.305-1.96-2.869
		c-1.65-0.817-2.346-2.173-2.747-3.879c-0.965-4.018-2.158-7.978-2.986-12.01c-0.412-2.011-0.767-3.951-2.006-5.637
		c-0.772-1.046-0.62-2.295-0.498-3.504c0.087-0.812,0.224-1.619,0.427-3.026c-0.995,0.944-1.604,1.514-2.046,1.93
		c0.361,2.641,0.62,5.093,1.066,7.511c0.229,1.229,0.716,2.442,1.244,3.585c1.158,2.478,1.858,5.037,1.97,8.094
		c-4.098-3.193-6.348-7.267-8.003-11.877c-0.833,0.538-1.036,1.214-1.122,1.97c-0.284,2.534-0.589,5.078-0.843,7.627
		c-0.158,1.574-0.27,3.144-0.346,4.723c-0.132,2.748,0.406,5.378,1.381,7.938c0.635,1.66,1.264,3.346,1.95,5.139
		c-2.503,0.584-4.707,1.311-6.941,0.508c-0.224-1.351-0.442-2.504-0.579-3.666c-0.045-0.341,0.021-0.854,0.244-1.031
		c1.32-1.066,0.98-2.376,0.33-3.463c-1.488-2.483-1.422-4.967-1.153-7.779c0.498-5.24,0.396-10.532,0.538-15.803
		c0.005-0.224,0.076-0.487-0.015-0.671c-0.97-2.102-0.005-3.742,1.188-5.372c0.762-1.046,0.975-2.143,0.731-3.57
		C0.898,60.14,0.162,50.157,0.73,40.11c0.163-2.938,0.747-5.814,1.793-8.574c1.396-3.712,3.94-6.396,7.561-7.977
		c3.869-1.688,7.993-2.501,12.116-3.364c5.353-1.123,10.451,0.015,15.61,1.125c5.428,1.17,10.852,2.476,16.336,3.26
		c3.164,0.452,6.49,0.284,9.673-0.125c4.905-0.635,9.75-1.77,14.635-2.575c1.625-0.267,3.316-0.333,4.946-0.193
		c0.985,0.083,2.006,0.615,2.869,1.17c6.662,4.293,12.893,9.13,18.728,14.523c4.59,4.237,8.287,9.135,11.699,14.299
		c0.752,1.127,1.514,2.252,2.27,3.375C122.048,59.61,125.161,64.139,126.116,70.009z M5.219,64.312
		c0.167,0.01,0.33,0.015,0.498,0.025c0.036-0.625,0.208-1.285,0.076-1.874c-0.68-3.112-1.437-6.208-2.163-9.308
		C3.655,56.959,4.214,60.671,5.219,64.312z"/>
</g>
        </svg>
      </SvgIcon>
    );
  }
  