import "./Datenschutzerklaerung.css";

export default function Datenschutzerklaerung() {
  return (
    <div className="legal">
      <h1>Datenschutzerklärung</h1>

      <h2>1. Einleitung</h2>
      <p>
        Ich freue mich, dass Sie meine Website besuchen und bedanke mich für Ihr
        Interesse. Der Schutz Ihrer persönlichen Daten ist mir ein wichtiges
        Anliegen. In dieser Datenschutzerklärung informiere ich Sie darüber,
        welche personenbezogenen Daten ich erhebe, wie ich diese Daten nutze und
        welche Rechte Ihnen im Zusammenhang mit Ihren Daten zustehen.
      </p>

      <h2>2. Verantwortliche Stelle</h2>
      <p>
        Verantwortliche Stelle für die Datenverarbeitung auf dieser Website bin
        ich:
      </p>
      <p>
        Michelle Laskowski
        <br />
        Carlssonweg 9c
        <br />
        22147 Hamburg
        <br />
        Deutschland
        <br />
        info@holistic-equestrian.de
        <br />
        +49 151 68439502
      </p>

      <h2>
        3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
        ihrer Verwendung
      </h2>
      <h3>a) Beim Besuch der Website</h3>
      <p>
        Beim Aufrufen meiner Website holistic-equestrian.de werden durch den auf
        Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen
        an den Server meiner Website gesendet. Diese Informationen werden
        temporär in einem sogenannten Logfile gespeichert. Folgende
        Informationen werden dabei ohne Ihr Zutun erfasst und bis zur
        automatisierten Löschung gespeichert:
      </p>
      <ul>
        <li>IP-Adresse des anfragenden Rechners</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Name und URL der abgerufenen Datei</li>
        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
        <li>
          Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
          der Name Ihres Access-Providers
        </li>
      </ul>
      <p>
        Die genannten Daten werden durch mich zu folgenden Zwecken verarbeitet:
      </p>
      <ul>
        <li>
          Gewährleistung eines reibungslosen Verbindungsaufbaus der Website
        </li>
        <li>Gewährleistung einer komfortablen Nutzung meiner Website</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zu weiteren administrativen Zwecken</li>
      </ul>
      <p>
        Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f
        DSGVO. Mein berechtigtes Interesse folgt aus den oben aufgelisteten
        Zwecken zur Datenerhebung.
      </p>

      <h3>b) Bei Nutzung meines Kontaktformulars</h3>
      <p>
        Bei Fragen jeglicher Art biete ich Ihnen die Möglichkeit, mit mir über
        ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei
        ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit ich
        weiß, von wem die Anfrage stammt und um diese beantworten zu können.
        Weitere Angaben können freiwillig getätigt werden.
      </p>
      <p>
        Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit mir erfolgt
        nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig
        erteilten Einwilligung.
      </p>

      <h2>4. Weitergabe von Daten</h2>
      <p>
        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den
        im Folgenden aufgeführten Zwecken findet nicht statt. Ich gebe Ihre
        persönlichen Daten nur an Dritte weiter, wenn:
      </p>
      <ul>
        <li>
          Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
          Einwilligung dazu erteilt haben,
        </li>
        <li>
          die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
          überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
          Daten haben,
        </li>
        <li>
          für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
          DSGVO eine gesetzliche Verpflichtung besteht, sowie
        </li>
        <li>
          dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für
          die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
        </li>
      </ul>

      <h2>5. Cookies</h2>
      <p>Ich verwende auf meiner Website keine Cookies.</p>

      <h2>6. Analyse-Tools</h2>
      <p>Ich verwende auf meiner Website keine Analyse-Tools.</p>

      <h2>7. Betroffenenrechte</h2>
      <p>Sie haben das Recht:</p>
      <ul>
        <li>
          gemäß Art. 15 DSGVO Auskunft über Ihre von mir verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
          offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
          Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
          die Herkunft ihrer Daten, sofern diese nicht bei mir erhoben wurden,
          sowie über das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling und ggf. aussagekräftigen Informationen zu
          deren Einzelheiten verlangen;
        </li>
        <li>
          gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei mir gespeicherten personenbezogenen Daten
          zu verlangen;
        </li>
        <li>
          gemäß Art. 17 DSGVO die Löschung Ihrer bei mir gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und ich die Daten nicht mehr benötige, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch
          gegen die Verarbeitung eingelegt haben;
        </li>
        <li>
          gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie mir
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen;
        </li>
        <li>
          gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber mir zu widerrufen. Dies hat zur Folge, dass ich die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortführen darf und
        </li>
        <li>
          gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
          der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
          üblichen Aufenthaltsortes oder Arbeitsplatzes oder meines Firmensitzes
          wenden.
        </li>
      </ul>

      <h2>8. Widerspruchsrecht</h2>
      <p>
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
        Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden,
        haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die
        Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
        Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder
        sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall
        haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
        besonderen Situation von mir umgesetzt wird.
      </p>
      <p>
        Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen,
        genügt eine E-Mail an [E-Mail-Adresse].
      </p>

      <h2>9. Datensicherheit</h2>
      <p>
        Ich verwende innerhalb des Website-Besuchs das verbreitete SSL-Verfahren
        (Secure Socket Layer) in Verbindung mit der jeweils höchsten
        Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird.
      </p>
    </div>
  );
}
