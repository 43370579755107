import { ThemeProvider, createTheme } from "@mui/material";
import { ReactNode } from "react";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    nb: true; //navbar
  }
}
export default function Themed({ children }: { children: ReactNode }) {
  const theme = createTheme({
    palette: { primary: { main: "#081f0a" }, secondary: { main: "#fff4e8" } },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        nb: 640, // defines when the navbar changes
      },
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
