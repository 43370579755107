import { SvgIcon } from "@mui/material";
import { iconSx } from "../Leistung";

export default function Akupunkturnadeln() {
  return (
    <SvgIcon sx={iconSx}>
      <svg
        fill="currentColor"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 202.031 202.031"
      >
        <g>
          <g>
            <path
              d="M167.756,1.513c-5.165-0.908-10.101,2.536-11.002,7.705c-0.724,4.065,1.284,7.951,4.688,9.882l-11.294,64.036l0.462,6.512
         l-11.631,65.965l-1.554,11.261l-17.072-17.073V89.765l1.575-6.333V18.416c3.684-1.311,6.345-4.783,6.345-8.92
         c0-5.244-4.263-9.496-9.502-9.496c-5.253,0-9.494,4.257-9.494,9.496c0,4.124,2.654,7.604,6.328,8.92V83.44l1.579,6.331v56.868
         l-21.705-21.707l-9.436-35.229l-0.118-6.522L69.099,20.372c3.209-2.219,4.874-6.263,3.814-10.254
         c-1.359-5.065-6.576-8.074-11.635-6.712c-5.068,1.352-8.068,6.566-6.721,11.628c1.071,3.984,4.54,6.66,8.433,6.974l16.827,62.808
         l3.164,5.71l7.204,26.881l-45.717-45.73c1.676-3.526,1.092-7.856-1.833-10.777c-3.705-3.707-9.714-3.707-13.425,0
         c-3.715,3.705-3.715,9.719,0,13.426c2.92,2.919,7.25,3.502,10.781,1.828l45.975,45.981l5.598,3.359l1.081,1.078l7.678,28.646
         l12.618,40.976l-9.56-41.784l-6.257-23.36l20.062,20.061v5.652l1.587,42.835l1.582-42.835v-2.484l16.525,16.536l-3.781,27.272
         l5.49-25.564l0.337,0.339l31.409,29.171l-29.17-31.401l-1.791-1.792l2.726-12.655l11.636-65.974l2.657-5.959l11.284-64.033
         c3.857-0.657,7.074-3.62,7.793-7.683C176.369,7.352,172.918,2.421,167.756,1.513z M112.429,9.496c0-3.496,2.84-6.33,6.337-6.33
         c3.493,0,6.331,2.834,6.331,6.33c0,3.492-2.838,6.332-6.331,6.332C115.269,15.828,112.429,12.988,112.429,9.496z M65.372,18.692
         c-3.378,0.901-6.853-1.105-7.755-4.479c-0.901-3.376,1.099-6.851,4.478-7.759c3.38-0.903,6.852,1.105,7.755,4.482
         C70.751,14.304,68.746,17.778,65.372,18.692z M40.393,72.085c-2.465,2.465-6.479,2.465-8.954,0c-2.465-2.471-2.465-6.481,0-8.953
         c2.47-2.473,6.483-2.473,8.954,0C42.864,65.604,42.864,69.609,40.393,72.085z M165.009,17.1c-3.445-0.604-5.743-3.893-5.136-7.335
         c0.602-3.439,3.895-5.743,7.33-5.144c3.447,0.607,5.748,3.896,5.138,7.346C171.742,15.409,168.445,17.71,165.009,17.1z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
