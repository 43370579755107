import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import "./App.css";
import Content from "./Content";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 320;
const navItems: { title: string; link: string }[] = [
  // { title: "Home", link: "./home" },
  { title: "Über mich", link: "./ueber-mich" },
  { title: "Kontakt", link: "./kontakt" },
  { title: "Leistungen", link: "./leistungen" },
  { title: "Preise", link: "./preise" },
];

export default function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        HolisticEquestrian
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => window.open(item.link, "_self")}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => window.open("./datenschutzerklaerung", "_self")}
          >
            <ListItemText primary="Datenschutzerklärung" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => window.open("./impressum", "_self")}
          >
            <ListItemText primary="Impressum" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar component="nav" color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { nb: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Button
            sx={{
              color: theme.palette.primary.contrastText,
              textTransform: "none",
            }}
            onClick={() => window.open("./", "_self")}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "block" }}
            >
              HolisticEquestrian
            </Typography>
          </Button>
          <Box className="navs" sx={{ display: { xs: "none", nb: "flex" } }}>
            <div></div>
            {navItems.map((item) => (
              <Button
                key={item.title}
                sx={{ color: theme.palette.primary.contrastText }}
                onClick={() => window.open(item.link, "_self")}
              >
                {item.title}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", nb: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.secondary.main
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{ p: 3 }}
        style={{ padding: 0, height: "100%", width: "100%" }}
      >
        <Toolbar />
        <Content />
      </Box>
      <footer
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        }}
      >
        <div id="footer-flex">
          <div id="copyright">
            &copy; 2024 Michelle Laskowski. Alle Rechte vorbehalten.
          </div>
          <div id="legal-links">
            <a href="./datenschutzerklaerung">Datenschutzerklärung</a>
            <a href="./impressum">Impressum</a>
          </div>
        </div>
      </footer>
    </Box>
  );
}
