import { SvgIcon } from "@mui/material";
import { iconSx } from "../Leistung";

export default function Blut() {
  return (
    <SvgIcon sx={iconSx}>
      <svg
        fill="currentColor"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 48 48"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="invisible_box" data-name="invisible box">
            <rect width="48" height="48" fill="none" />
          </g>
          <g id="Layer_7" data-name="Layer 7">
            <path d="M38,32.8,32,26l-6,6.8a8.2,8.2,0,0,0-2,5.8,9.1,9.1,0,0,0,1,3.3H24a6,6,0,0,1-6-6V12H30v9a2,2,0,0,0,4,0V12h2a2,2,0,0,0,0-4H32V2H16V8H12a2,2,0,0,0,0,4h2V36A10,10,0,0,0,24,46a8.7,8.7,0,0,0,4.2-1A7.5,7.5,0,0,0,32,46a8,8,0,0,0,5.2-1.9A7.9,7.9,0,0,0,38,32.8ZM20,6h8V8H20Z" />
          </g>
        </g>{" "}
      </svg>
    </SvgIcon>
  );
}
