import { Button, Typography, useTheme } from "@mui/material";

export type PreisType = {
  leistung: string;
  beschreibung: string;
  preis: string;
};

export default function PreisBox(props: { preis: PreisType }) {
  const theme = useTheme();
  return (
    <div
      className="preis-box"
      style={{
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <div className="preis-content">
        <Typography variant="h4" textAlign="center">
          {props.preis.leistung}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {props.preis.beschreibung}
        </Typography>
        <Typography variant="body1" textAlign="center" fontWeight="bold">
          {props.preis.preis}
        </Typography>
        <Button style={{textTransform: "none"}} variant="contained" onClick={() => window.open("./kontakt?betreff=Terminanfrage+" + props.preis.leistung.replace(" ", "+"), "_self")} >Termin vereinbaren</Button>
      </div>
    </div>
  );
}
